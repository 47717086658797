import React from "react"
import { Helmet } from "react-helmet";
import ReferenceRequest from "../../Views/Website/Reference/Reference";
import Layout from "../../components/Layout/layout";

const ReferenceRequestPage: React.FC = () => {
  return (
    <Layout NoHeader={true} NoFooter={true}>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Reference Request</title><meta charSet='utf-8' />
      </Helmet>

      <ReferenceRequest />
    </Layout>
  );
};

export default ReferenceRequestPage;